import React, { Component } from 'react';
import Section from "../components/forms/Section.js";
import StandardResponsiveContainer from "../components/responsive/standardResponsiveContainer";
//import { PedidoFormSchema } from "../schemas/PedidoFormSchema.js";
import { Button } from "reactstrap";

import TopBar from '../components/menus/topBar';
//material ui fields imports
import { TextField } from '@mui/material';
import { FormGroup } from '@mui/material';
import { FormControl } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
import { RadioGroup } from '@mui/material';
import { Radio } from '@mui/material';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Destinatario from '../components/forms/Destinatario.js';
import getCecoList from '../api/getCecos.js';
import { useNavigate } from 'react-router-dom';
import PedidoFormStyle from './PedidoFormStyle.css';
import { height } from '@mui/system';
import insertPedido from '../api/insertPedido.js';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';

const PedidoFormSchema = [
    {
        title: "Nuevo Pedido",
        fields: [
            {
                name: "fecha",
                label: "Fecha",
                type: "date",
                defaultValue: new Date(),
            },
            {
                name: "franjaHoraria",
                label: "Franja Horaria",
                type: "select",
                options: [
                    {
                        value: "mañana",
                        label: "Mañana",
                    },
                    {
                        value: "tarde",
                        label: "Tarde",
                    },
                ],
                required: true,
            },
            {
                name: "telefono",
                label: "Teléfono",
                type: "number",
            },
        ],
    },
    {
        title: "Tipo de Servicio",
        fields: [
            {
                name: "tipoServicio",
                label: "Tipo de Servicio",
                type: "select",
                required: true,
                options: [
                    {
                        value: "moto/mensajeria",
                        label: "Moto/mensajeria",
                    },
                    {
                        value: "Flete",
                        label: "Flete",
                    },
                ],
            },
        ],
    },
    {
        title: "Tipo de Tramite",
        fields: [
            {
                name: "tipoTramite",
                label: "Tipo de Tramite",
                type: "select",
                required: true,
                options: [
                    {
                        value: "normal",
                        label: "Normal",
                    },
                    {
                        value: "exclusivo",
                        label: "Exclusivo",
                    },
                ],
            },
        ],
    },
    {
        title: "CECO",
        fields: [
            {
                name: "ceco",
                label: "CECO",
                type: "textarea",
            },
        ],
    },
    {
        title: "Describe el Tramite",
        fields: [
            {
                name: "descripcion",
                label: "Describe el Tramite",
                type: "textarea",
                required: true,
            },
        ],

    },
    {
        title: "Destinatario",
        required: true,
        fields: [
            {
                name: "destinatario",
                label: "Destinatario",
                type: "component",
                defaultValue: [
                    {
                        Empresa: "",
                        Nombre: "",
                        Calle: "",
                        Numero: "",
                        Piso: "",
                        Localidad: "",
                    }
                ]
            },
        ],
    },
    {
        title: "Dias de Atencion",
        fields: [
            {
                name: "Lunes",
                label: "Lunes",
                type: "checkbox",
                defaultValue: false,
            },
            {
                name: "Martes",
                label: "Martes",
                type: "checkbox",
                defaultValue: false,
            },
            {
                name: "Miercoles",
                label: "Miercoles",
                type: "checkbox",
                defaultValue: false,
            },
            {
                name: "Jueves",
                label: "Jueves",
                type: "checkbox",
                defaultValue: false,
            },
            {
                name: "Viernes",
                label: "Viernes",
                type: "checkbox",
                defaultValue: false,
            }
        ],
    },
];



class PedidoForm extends Component {
    state = {};


    async componentDidMount() {
        //if no token in session go to login
        if (!sessionStorage.getItem("token")) {
            this.props.navigate("/login");
        }
        //get cecos from axios request and set state
        /*getCecoList().then(async (cecos) => {
            await this.setState({ cecos: cecos.data });
            console.log(this.state.cecos);
        }).catch((error) => {
            console.log(error);
            //if 401, redirect to login
            if (error.response.status === 401) {
                //useNavigate to redirect to login
                this.props.navigate("/login");
            }
        });*/
    }

    //constructor to set state
    constructor(props) {
        super(props);
        let initialState = {};
        PedidoFormSchema.forEach((section) => {
            section.fields.forEach((field) => {
                initialState[field.name] = field.defaultValue || "";
            });
        });
        initialState = {
            ...initialState,
            needsCeco: false,
            cecos: [],
            openModal: false,
            idPedido: null,
            errors: {},
            formSubmited: false,
        };
        this.state = initialState;
    }

    submitform = async (e) => {
        e.preventDefault();
        await this.setState({ formSubmited: true });
        let isValid = await this.formValidation();
        //alert(isValid);
        console.log(this.state.errors);
        if (!isValid) return;

        //formate fecha to posgresql format
        let fecha = moment(this.state.fecha).format('YYYY-MM-DD');
        //update fecha to state
        this.setState({ fecha: fecha });
        let response = await insertPedido(this.state);
        //if 401, redirect to login
        if (response.status === 401) {
            //useNavigate to redirect to login
            this.props.navigate("/login");
        }

        //get idPedido from response
        let idPedido = response.data.idPedido;

        //set state idPedido and open modal
        this.setState({ idPedido: idPedido, openModal: true });
    }

    onChange = async e => {
        await this.setState({ [e.target.name]: e.target.value });
        if (this.state.tipoServicio === "moto/mensajeria" && this.state.tipoTramite === "normal") {
            await this.setState({ needsCeco: false });
        }
        if (this.state.tipoServicio === "Flete" || this.state.tipoTramite === "exclusivo") {
            await this.setState({ needsCeco: true });
        }
        await this.formValidation();
    };

    addUpdateDestinatario = (index, destinatario) => {
        const destinatarios = this.state.destinatario;
        destinatarios[index] = destinatario;
        this.setState({ destinatario: destinatarios });
        console.log(this.state);
    }

    handleClose = () => {
        this.setState({ openModal: false });
        this.clearForm();
        //navigate to home
        this.props.navigate("/home");
    }

    clearForm = () => {
        this.setState({
            ...this.state,
            ...PedidoFormSchema.reduce((acc, section) => {
                section.fields.forEach((field) => {
                    acc[field.name] = field.defaultValue || "";
                });
                return acc;
            }, {}),
        });
    }

    formValidation = async () => {
        if (!this.state.formSubmited) return false;
        let isValid = true;
        let errors = {};
        PedidoFormSchema.forEach((section) => {
            section.fields.forEach((field) => {
                if (field.required && (!this.state[field.name] || this.state[field.name] === "")) {
                    isValid = false;
                    errors[field.name] = "Este campo es requerido";
                }
            });
        });

        this.state.destinatario.forEach((destinatario, index) => {
            if (!destinatario.empresa || "" === destinatario.Empresa) {
                isValid = false;
                errors[`destinatario[${index}].Empresa`] = "Este campo es requerido";
            }
            if (!destinatario.nombre || "" === destinatario.Nombre) {
                isValid = false;
                errors[`destinatario[${index}].Nombre`] = "Este campo es requerido";
            }
            if (!destinatario.apellido || "" === destinatario.Apellido) {
                isValid = false;
                errors[`destinatario[${index}].Apellido`] = "Este campo es requerido";
            }
            if (!destinatario.calle || "" === destinatario.Calle) {
                isValid = false;
                errors[`destinatario[${index}].Calle`] = "Este campo es requerido";
            }
            if (!destinatario.numero || "" === destinatario.Numero) {
                isValid = false;
                errors[`destinatario[${index}].Numero`] = "Este campo es requerido";
            }
        });
        await this.setState({ errors });
        return isValid;
    }

    removeDestinatario = (index) => {
        if(this.state.destinatario.length === 1) return;
        const destinatarios = this.state.destinatario;
        destinatarios.splice(index, 1);
        this.setState({ destinatario: destinatarios });
    }

    render() {
        return (
            <div className='backgroud-color'>
                <TopBar />
                <StandardResponsiveContainer>

                    <Modal
                        open={this.state.openModal}
                        onClose={this.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        //modal style
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            height: 100,
                            bgcolor: '#fff',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Box>
                            <div className='modal-content'>
                                <p>tu id de pedido es: {this.state.idPedido}</p>
                                <Button onClick={this.handleClose}>Aceptar</Button>
                            </div>
                        </Box>
                    </Modal>
                    <form>
                        <h4 className="section-title">Nuevo Pedido</h4><br />
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={3} sm={4} md={4} lg={4}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                                        <div>Fecha: </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={9} sm={8} md={8} lg={8}>
                                    <TextField
                                        variant="standard"
                                        id="fecha"
                                        label="Fecha"
                                        type="date"
                                        defaultValue={new Date().toISOString().substr(0, 10)}
                                        disabled={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                                        <div>Franja Horaria: </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-label="franjaHoraria"
                                            name="franjaHoraria"
                                            value={this.state.franjaHoraria}
                                            onChange={this.onChange}
                                            style={{
                                                alignContent: "start",
                                            }}
                                            row
                                        >
                                            <FormControlLabel value="mañana" control={<Radio />} label="Mañana" />
                                            <FormControlLabel value="tarde" control={<Radio />} label="Tarde" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {this.state.errors.franjaHoraria && (
                                    <div className="error">{this.state.errors.franjaHoraria}</div>
                                )}
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                                        <div>Telefono: </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <TextField
                                        variant="standard"
                                        id="telefono"
                                        label="Teléfono"
                                        name="telefono"
                                        type="number"
                                        defaultValue=""
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={this.onChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <h4 className="section-title">tipo de servicio</h4><br />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                                        <div>Tipo de servicio: </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={9} sm={8} md={8} lg={8}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-label="tipoServicio"
                                            name="tipoServicio"
                                            value={this.state.tipoServicio}
                                            onChange={this.onChange}
                                            row
                                        >
                                            <FormControlLabel value="moto/mensajeria" control={<Radio />} label="Moto/mensajeria" />
                                            <FormControlLabel value="Flete" control={<Radio />} label="Flete" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {this.state.errors.tipoServicio && (
                                    <div className="error">{this.state.errors.tipoServicio}</div>
                                )}
                            </Grid>
                            <h4 className="section-title">tipo de tramite</h4><br />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                                        <div>Tipo de tramite: </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={9} sm={8} md={8} lg={8}>
                                    <FormControl>
                                        <RadioGroup
                                            aria-label="tipoTramite"
                                            name="tipoTramite"
                                            value={this.state.tipoTramite}
                                            onChange={this.onChange}
                                            row
                                        >
                                            <div className="row">
                                                <FormControlLabel value="normal" control={<Radio />} label="Normal" />
                                                <FormControlLabel value="exclusivo" control={<Radio />} label="Exclusivo" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {this.state.errors.tipoTramite && (
                                    <div className="error">{this.state.errors.tipoTramite}</div>
                                )}
                            </Grid>
                            <h4 className="section-title">CECO</h4><br />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={3} sm={4} md={4} lg={4}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                                        <div>CECO: </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={9} sm={8} md={8} lg={8}>
                                    <TextField
                                        variant="standard"
                                        id="ceco"
                                        label="CECO"
                                        name="ceco"
                                        defaultValue=""
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={!this.state.needsCeco}
                                        autoComplete="off"
                                        onChange={this.onChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <h4 className="section-title">descripcion del tramite</h4><br />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                                        <div>Descripción del tramite: </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <TextField
                                        variant="standard"
                                        id="descripcion"
                                        name="descripcion"
                                        label="Describe el Tramite"
                                        type="textarea"
                                        defaultValue=""
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        autoComplete="off"
                                        onChange={this.onChange}
                                        size="small"
                                    />
                                </Grid>
                                {this.state.errors.descripcion && (
                                    <div className="error">{this.state.errors.descripcion}</div>
                                )}
                            </Grid>
                            <h4 className="section-title">destinatario</h4><br />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl style={{ width: "100%"}}>
                                        {this.state.destinatario.map((destinatario, index) => (
                                            <Card sx={{ minWidth: 275 }} className="destinatarioCard">
                                                <Destinatario 
                                                    errors={this.state.errors} 
                                                    index={index} 
                                                    destinatario={destinatario} 
                                                    addUpdateDestinatario={this.addUpdateDestinatario} 
                                                    removeDestinatario={this.removeDestinatario}
                                                />
                                            </Card>
                                        ))}
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Button variant="standard" color="secondary"
                                                style={{
                                                    marginTop: "10px",
                                                    marginBottom: "10px",
                                                    width: "60%",
                                                    padding: "0",
                                                }}

                                                onClick={() => this.setState({ destinatario: [...this.state.destinatario, {}] })}>
                                                + Agregar Destinatario
                                            </Button>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <h4 className="section-title">dias de atencion</h4><br />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <FormControl>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.lunes} onChange={this.onChange} name="lunes" />}
                                            label="Lunes"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.martes} onChange={this.onChange} name="martes" />}
                                            label="Martes"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.miercoles} onChange={this.onChange} name="miercoles" />}
                                            label="Miercoles"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.jueves} onChange={this.onChange} name="jueves" />}
                                            label="Jueves"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={this.state.viernes} onChange={this.onChange} name="viernes" />}
                                            label="Viernes"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            {/*<h4 className="section-title">direccion</h4><br />
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                                        <div>Direccion: </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <TextField
                                        variant="standard"
                                        id="direccion"
                                        label="Direccion"
                                        type="text"
                                        name="direccion"
                                        defaultValue="direccion"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>*/}
                        </Box>
                        <Box className="box-button">
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                <Button variant="contained" color="primary" onClick={this.submitform}>
                                    Guardar
                                </Button>
                            </Grid>
                        </Box>
                    </form>
                </StandardResponsiveContainer>
            </div>
        )
    };
};

function PedidoWithNavigate(props) {
    let navigate = useNavigate();
    return <PedidoForm {...props} navigate={navigate} />
}


export default PedidoWithNavigate;






