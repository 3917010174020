//react compoeneent for standard responsive container
import eact from 'react';

const StandardResponsiveContainer = (props) => {
    return (
        <div className="container" width="100vw" style={{maxWidth: "100vw"}}>
            <div className="row justify-content-center align-items-center">
                <div className="col-md-6 col-lg-12 col-sm-6 mx-auto text-center">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default StandardResponsiveContainer;


//check to implement later

/*var StandardResponsiveContainer = function (_React$Component) {
  _inherits(StandardResponsiveContainer, _React$Component);

  function StandardResponsiveContainer(props) {
    _classCallCheck(this, StandardResponsiveContainer);

    var _this = _possibleConstructorReturn(this, (StandardResponsiveContainer.__proto__ || Object.getPrototypeOf(StandardResponsiveContainer)).call(this, props));

    _this.state = {
      width: window.innerWidth
    };
    return _this;
  }

  _createClass(StandardResponsiveContainer, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      window.addEventListener('resize', this.updateDimensions.bind(this));
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions.bind(this));
    }
  }, {
    key: 'updateDimensions',
    value: function updateDimensions() {
      this.setState({ width: window.innerWidth });
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          className = _props.className,
          style = _props.style,
          breakpoints = _props.breakpoints,
          defaultBreakpoint = _props.defaultBreakpoint;

      var _state = this.state,
          width = _state.width,
          breakpoint = breakpoints[defaultBreakpoint];

      for (var key in breakpoints) {
        if (width > breakpoints[key]) {
          breakpoint = breakpoints[key];
        }
      }

      return _react2.default.createElement(
        'div',
        { className: className, style: style },
        children[breakpoint]
      );
    }
  }]);

  return StandardResponsiveContainer;
}*/