import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cross from "../../assets/imgs/cross.png";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Link } from "react-router-dom";
import getTarea from "../../api/getTareaRequest";
import getMotivos from "../../api/getMotivosRequest";
import rechazarTarea from "../../api/RechazarTareaRequest";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "./RechazarPedido.css";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

const NoRealizarPedido = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [tarea, setTarea] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [base64Foto, setBase64Foto] = useState(null);
  const [fotos, setFotos] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [motivo, setMotivo] = useState(null);
  const [comentario, setComentario] = useState("");
  const [verFotos, setVerFotos] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);

  const fetchTarea = async (id) => {
    setLoading(true);
    const respone = await getTarea(id);

    if (respone.status === 200) {
      await setTarea(respone.data.tarea);
    } else {
      setError(true);
      setErrors(respone.data.error);
    }
    setLoading(false);
  };

  const fetchMotivos = async (id) => {
    setLoading(true);
    const respone = await getMotivos();

    if (respone.status === 200) {
      setMotivos(respone.data.motivos);
      if (motivos.length > 0) {
        setMotivo(motivos[0].ID_MOTIVO);
      }
    } else {
      setError(true);
      setErrors(respone.data.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTarea(id);
    fetchMotivos();
  }, []);

  const encodeImageFileAsURL = (element) => {
    setErrors([]);
    var file = element.files[0];
    if (!file.type.includes("image/")) {
      setShowErrorToast(true);
      return;
    }
    var reader = new FileReader();
    reader.onloadend = function () {
      setBase64Foto(reader.result);
      const newFotos = [...fotos, reader.result];
      setFotos(newFotos);
    };
    reader.readAsDataURL(file);
  };

  const handleMotivoChange = (event) => {
    setErrors([]);
    setMotivo(event.target.value);
  };

  const handleComentarioChange = (event) => {
    setErrors([]);
    setComentario(event.target.value);
  };

  const rechazarPedido = async () => {
    try {
      setResponseLoading(true);
      //validate motivo, foto and comentario
      let newErrors = [];

      if (!motivo) {
        newErrors.push("Seleccione un motivo");
      }

      let otroMotivoId = motivos.find(
        (motivo) =>
          motivo.DES_MOTIVO.toUpperCase() === "Otros Motivos".toUpperCase()
      ).id;
      if (!comentario && (!motivo || motivo === otroMotivoId)) {
        newErrors.push("Ingrese un comentario");
      }

      await setErrors(newErrors);
      if (newErrors.length > 0) {
        return;
      }

      const tarea = {
        id: id,
        motivo: motivo,
        comentario: comentario,
        fotos: fotos,
      };

      const respone = await rechazarTarea(tarea);

      if (respone.status === 200) {
        navigate("/listaTareas");
      } else {
        setError(true);
        setErrors(respone.data.error);
      }
      setResponseLoading(false);
    } catch (error) {
      setError(true);
      setErrors("Ocurrio un error al realizar la tarea");
      setResponseLoading(false);
    }
  };

  const removeFotoFromArray = (index) => {
    const newFotos = fotos.filter((foto, i) => i !== index);
    setFotos(newFotos);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowErrorToast(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div style={{ maxWidth: "96vw" }}>
      <Snackbar
        open={showErrorToast}
        autoHideDuration={6000}
        onClose={() => setShowErrorToast(false)}
        message="El Archivo tiene una extensión no permitida"
        action={action}
      />
      <div class="row justify-content-center">
        <img src={cross} class="imgNoti" alt="imagen confirmacion pedido" />
      </div>
      <div class="row justify-content-center">
        <span class="tramiteR">Rechazar Entrega.</span>
      </div>
      <div class="row justify-content-center mt-3 bg-rojo">
        <span class="container-fluid nroEntrega">Direccion</span>
        <span class="container-fluid nroEntrega">
          Tramite N° {tarea.ID_PEDIDO}
        </span>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 d-flex">
          <div class="col-6">
            <span class="eti">Fecha de tramite:</span>
          </div>
          <div class="col-6">
            <span class="border border-white fs-italic">
              {tarea.FEC_TAREAPED
                ? moment(tarea.FEC_TAREAPED).format("DD/MM/YYYY")
                : "---"}
            </span>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="col-6">
            <span class="eti">Empresa:</span>
          </div>
          <div class="col-6">
            <span class="border border-white fs-italic">
              {tarea.EMPRE_TAREAPED}
            </span>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="col-6">
            <span class="eti">Nombre:</span>
          </div>
          <div class="col-6">
            <span class="border border-white fs-italic">
              {tarea.NOMBRE_TAREAPED} {tarea.APELLIDO_TAREAPED}
            </span>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="col-6">
            <span class="eti">Calle:</span>
          </div>
          <div class="col-6">
            <span class="border border-white fs-italic">
              {tarea.CALLE_TAREAPED}
            </span>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="col-6">
            <span class="eti">Numero:</span>{" "}
            <span class="border border-white fs-italic">
              {tarea.NRO_TAREAPED}
            </span>
          </div>
          <div class="col-6">
            <span class="eti">Piso:</span>{" "}
            <span class="border border-white fs-italic">
              {tarea.PISO_TAREAPED}
            </span>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="col-6">
            <span class="eti">Localidad:</span>
          </div>
          <div class="col-6">
            <span class="border border-white fs-italic">
              {tarea.LOCA_TAREAPED}
            </span>
          </div>
        </div>
      </div>

      <div class="row justify-content-center mt-3">
        <form>
          <div class="form-group">
            <label for="exampleFormControlSelect1" class="eti">
              Motivos de no realización
            </label>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              onChange={handleMotivoChange}
            >
              {Array.isArray(motivos)
                ? motivos.map((motivo) => (
                    <option
                      key={"motivo" + motivo.ID_MOTIVO}
                      value={motivo.ID_MOTIVO}
                    >
                      {motivo.DES_MOTIVO}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">
              Detalle los otros motivos
            </label>
            <TextareaAutosize
              aria-label="comentario"
              minRows={3}
              placeholder="Ingrese un comentario"
              style={{ minWidth: 200 }}
              className="form-control fs-italic"
              onChange={handleComentarioChange}
            />
          </div>
        </form>
      </div>
      <h5 class="text-bold text-center my-3"></h5>
      <br></br>
      <div class="row justify-content-center">
        <label for="file-upload" class="custom-file-upload">
          Tomar fotografía
        </label>
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          capture="camera"
          onChange={(event) => {
            encodeImageFileAsURL(event.target);
          }}
        />
        <label for="file-upload" class="custom-file-upload">
          Adjuntar fotografía
        </label>
        <input
          id="file-upload"
          type="file"
          onChange={(event) => {
            encodeImageFileAsURL(event.target);
          }}
        />
      </div>
      {verFotos ? (
        <div class="row justify-content-center align-content-center">
          <Button onClick={() => setVerFotos(false)}>Ocultar Fotos</Button>
          {fotos.map((foto, index) => {
            return (
              <div
                key={"foto" + index}
                class="row justify-content-center"
                style={{ marginTop: "10px" }}
              >
                <img
                  className="img-preview"
                  style={{
                    maxWidth: "45%",
                    maxHeight: "15vh",
                    marginRight: "10px",
                  }}
                  src={foto}
                  alt=""
                />
                <CancelIcon onClick={() => removeFotoFromArray(index)}>
                  Borrar foto
                </CancelIcon>
              </div>
            );
          })}
        </div>
      ) : (
        <div class="row justify-content-center align-content-center">
          <Button onClick={() => setVerFotos(true)}>Ver Fotos</Button>
          {fotos && fotos.length > 0 ? (
            <div className="blue-circle">{fotos.length}</div>
          ) : (
            <></>
          )}
        </div>
      )}
      <br />
      <div class="row justify-content-center mt-3">
        <button
          type="button"
          class="btn btn-guardarComentario"
          onClick={rechazarPedido}
        >
          Confirmar
        </button>
        {responseLoading ? <CircularProgress /> : <></>}
      </div>
      {/*display errors*/}
      {errors.length > 0 && (
        <div class="row justify-content-center mt-3">
          {errors.map((error, index) => (
            <div key={"error" + index} class="alert alert-danger" role="alert">
              <div>{error}</div>
            </div>
          ))}
        </div>
      )}

      <div class="row justify-content-center my-5">
        <Link to="/listaTareas" class="volver">
          <i class="far fa-arrow-alt-circle-left "></i> Volver a direcciones
        </Link>
      </div>
    </div>
  );
};

export default NoRealizarPedido;
