import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Login from './pages/login';
import Home from './pages/Home';
import Pedido from './pages/PedidoForm';
import ListaTareas from './pages/ListaTareas';
import RealizarPedido from './pages/RealizarPedido/RealizarPedido';
import RechazarPedido from './pages/RechazarPedido/RechazarPedido';
import AppInfo from '../package.json';

//import context

//require('dotenv').config();

//create context userContext


function App() {
  return (
    <div style={{maxWidth: "100vw"}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} default />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/pedido" element={<Pedido />} />
          <Route path="/listaTareas" element={<ListaTareas />} />
          <Route path="/realizar/:id" element={<RealizarPedido />} />
          <Route path="/rechazar/:id" element={<RechazarPedido />} />
        </Routes>
      </BrowserRouter>
      <div className='version-label'>V{AppInfo.version}-{process.env.REACT_APP_ENV}</div>
    </div>
  );
}

export default App;
