import axios from 'axios';

const listadoTareasRequest = (filter) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
        }
    }
    const baseUrl = process.env.REACT_APP_BACKEND_BASEURL ? process.env.REACT_APP_BACKEND_BASEURL : 'https://motos-desa.b4code.com/';
    return axios.post(`${baseUrl}AppPedido/getTareasByMensajero`, filter, config).then(response => {
        return response;
    }).catch(error => {
        return error.response;
    });
};

export default listadoTareasRequest;