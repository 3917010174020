import axios from 'axios';

const getMotivos = () => {    
    //get token from local storage and set to header
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
        }
    }
    const baseUrl = process.env.REACT_APP_BACKEND_BASEURL ? process.env.REACT_APP_BACKEND_BASEURL : 'https://motos-desa.b4code.com/';
    return axios.get(`${baseUrl}/AppPedido/motivosRechazo`, config);
};

export default getMotivos;