import React from "react";
//import sanofiLogo from "../../assets/iconos/sanofi/512x512.png";
import topBarStyle from "./topBarStyle.css";
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import AppInfo from '../../../package.json';

import sanofiLogo from "../../assets/imgs/favicon.ico";


//top bar component with user name and logo
const TopBar = ({ navigate }) => {
    //use state show log out button
    const [showLogOut, setShowLogOut] = React.useState(false);

    //function to get user from NOM_USUARI and APE_USUARI from local storage
    const getUser = () => {
        const NOM_USUARI = localStorage.getItem('NOM_USUARI');
        const APE_USUARI = localStorage.getItem('APE_USUARI');
        return `Hola, ${NOM_USUARI} ${APE_USUARI}`;
    }

    const getLogo = () => {
        return (
            //keep height to container
            <div className="logo-container">
                <img src={sanofiLogo} alt="Sanofi" className="topBarLogo" />
            </div>
        )
    }
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('NOM_USUARI');
        localStorage.removeItem('APE_USUARI');
        localStorage.setItem('IS_LOGGED', "false");
        navigate('/');
    }

    return (
        <div className="top-bar">
            {/*<Grid container>
                <Grid item xs={9}>
                    <PersonIcon onClick={() => setShowLogOut(!showLogOut)} className="icon-style"></PersonIcon>
                    <button
                        className={showLogOut ? "btn btn-danger logout-button" : "logout-button-hidden"}
                        onClick={logout}
                    >Cerrar Sesion</button>
                    <span className="userNameMsg">{getUser()}</span>

                </Grid>
                <Grid item xs={1}>
                    {AppInfo.version}
                </Grid>
                <Grid item xs={2}>
                    {getLogo()}
                </Grid>
                
            </Grid>*/}
            <nav class="navbar navbar-light bg-transparent">
                <div class="d-flex">
                    <i onClick={() => setShowLogOut(!showLogOut)} class="fas fa-user mx-2 user lila"></i>
                    <button
                        className={showLogOut ? "btn btn-danger logout-button" : "logout-button-hidden"}
                        style={{ zIndex: "1000" }}
                        onClick={logout}
                    >Cerrar Sesion</button>
                    <h6 class="lila">{getUser()}</h6>
                </div>
                <a class="navbar-brand" href="#">
                    <span class="empresa lila">SANOFI </span>
                    <img src={sanofiLogo} width="30" height="30" class="d-inline-block align-top" alt="" />
                </a>
            </nav>
        </div>
    )
}

function TopBarWithNavigate(props) {
    let navigate = useNavigate();
    return <TopBar {...props} navigate={navigate} />
}

export default TopBarWithNavigate;





