import React, { useEffect} from 'react';
import StandardResponsiveContainer from '../components/responsive/standardResponsiveContainer';
import TopBar from '../components/menus/topBar';
import "./HomePage.css"

//home component with a link button to form page
const Home = () => {
    return (
        <div className='home-container '>
        <TopBar />
        <StandardResponsiveContainer>    
            <div>
                <h1>Home</h1>
                <button>
                    <a href="/pedido">Realizar Pedido</a>
                </button>
            </div>
        </StandardResponsiveContainer>
        </div>
    );
}

export default Home;