import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Styled from '@emotion/styled';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//form fields
//Empresa: "",
//Nombre: "",
//Calle: "",
//Numero: "",
//Piso: "",
//Localidad: "",

const DestinatarioLabel = Styled.div`
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.5rem;
    width: 100%;
    text-align: left;
    background-color: #f5f5f5;
    border: black solid 1px;
    border-radius: 0.25rem;
    `;



const Destinatario = ({
    addUpdateDestinatario,
    index,
    destinatario,
    errors,
    removeDestinatario
}) => {

    const [empresa, setEmpresa] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [calle, setCalle] = useState("");
    const [numero, setNumero] = useState("");
    const [piso, setPiso] = useState("");
    const [localidad, setLocalidad] = useState("");

    const handleChange = async (event) => {
        const { name, value } = event.target;
        //use dinamic index
        const setters = {
            empresa: setEmpresa,
            nombre: setNombre,
            apellido: setApellido,
            calle: setCalle,
            numero: setNumero,
            piso: setPiso,
            localidad: setLocalidad
        }
        await setters[name](value);
        addUpdateDestinatario(index, {
            empresa,
            nombre,
            apellido,
            calle,
            numero,
            piso,
            localidad
        });
    }

    //add correct number termination in spanish, ex 1ro, 2do, 3ro, 4to, etc
    const getNumero = (numero) => {
        if (numero == "1") return "1er";
        if (numero == "2") return "2do";
        if (numero == "3") return "3er";
        if (numero == "4") return "4to";
        if (numero == "5") return "5to";
        if (numero == "6") return "6to";
        if (numero == "7") return "7mo";
        if (numero == "8") return "8vo";
        if (numero == "9") return "9no";
        if (numero == "10") return "10mo";
        return numero + "vo";
    }


    //order fields in rows, numero y piso go on the same row
    return (
        <Box sx={{ flexGrow: 1 }}>
            <DestinatarioLabel>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">

                    <Grid item xs={10} sm={11} md={11} lg={11}>
                        {getNumero(index + 1)} Destinatario
                    </Grid>
                    <Grid item xs={2} sm={1} md={1} lg={1}>
                        <DeleteForeverIcon className="deleteIcon" onClick={removeDestinatario} ></DeleteForeverIcon>
                    </Grid>
                </Grid>
            </DestinatarioLabel>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={3} sm={4} md={4} lg={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <div>Empresa: </div>
                    </Grid>
                </Grid>
                <Grid item xs={9} sm={11} md={8} lg={8}>
                    <TextField
                        variant="standard"
                        name={"empresa"}
                        label="Empresa"
                        value={empresa}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        size="small"
                    />
                </Grid>
                {errors[`destinatario[${index}]`] && errors[`destinatario[${index}].empresa`] && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className="error">{errors[destinatario.index].empresa}</p>
                    </Grid>
                )}
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={3} sm={4} md={4} lg={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <div>Nombre: </div>
                    </Grid>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={8}>
                    <TextField
                        variant="standard"
                        name={"nombre"}
                        label="Nombre"
                        value={nombre}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        size="small"
                    />
                </Grid>
                {errors[`destinatario[${index}]`] && errors[`destinatario[${index}].nombre`] && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className="error">{errors[destinatario.index].nombre}</p>
                    </Grid>
                )}
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={3} sm={4} md={4} lg={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <div>Apellido: </div>
                    </Grid>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={8}>
                    <TextField
                        variant="standard"
                        name={"apellido"}
                        label="Apellido"
                        value={apellido}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        size="small"
                    />
                </Grid>
                {errors[`destinatario[${index}]`] && errors[`destinatario[${index}].apellido`] && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className="error">{errors[destinatario.index].apellido}</p>
                    </Grid>
                )}
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={3} sm={4} md={4} lg={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <div>Calle: </div>
                    </Grid>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={8}>
                    <TextField
                        variant="standard"
                        name={"calle"}
                        label="Calle"
                        value={calle}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        size="small"
                    />
                </Grid>
                {errors[`destinatario[${index}]`] && errors[`destinatario[${index}].calle`] && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className="error">{errors[destinatario.index].calle}</p>
                    </Grid>
                )}
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={3} sm={4} md={4} lg={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <div>Numero: </div>
                    </Grid>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={8}>
                    <TextField
                        variant="standard"
                        name={"numero"}
                        label="Numero"
                        value={numero}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        size="small"
                    />
                </Grid>
                {errors[`destinatario[${index}]`] && errors[`destinatario[${index}].numero`] && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className="error">{errors[destinatario.index].numero}</p>
                    </Grid>
                )}
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={3} sm={4} md={4} lg={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <div>Piso: </div>
                    </Grid>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={8}>
                    <TextField
                        variant="standard"
                        name={"piso"}
                        label="Piso"
                        value={piso}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={3} sm={4} md={4} lg={4}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                        <div>Localidad: </div>
                    </Grid>
                </Grid>
                <Grid item xs={9} sm={8} md={8} lg={8}>
                    <TextField
                        variant="standard"
                        name={"localidad"}
                        label="Localidad"
                        value={localidad}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default Destinatario;
