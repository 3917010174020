import axios from 'axios';

const loginMensajeroRequest = (username, password) => {
    //post request to AppLogin/login with username and password
    //return data from response
    const baseUrl = process.env.REACT_APP_BACKEND_BASEURL ? process.env.REACT_APP_BACKEND_BASEURL : 'https://motos-desa.b4code.com/';
    return axios.post(`${baseUrl}AppLogin/loginMensajero`, {
        username,
        password
    }).then(response => {
        return response;
    }).catch(error => {
        return error.response;
    });
};

export default loginMensajeroRequest;