import React, { useEffect, useLayoutEffect, useState } from 'react';
import StandardResponsiveContainer from '../components/responsive/standardResponsiveContainer';
import TopBar from '../components/menus/topBar';
import "./ListaTareas.css"
import listadoTareasRequest from '../api/listadoTareasRequest.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import TramitesIcono from '../assets/imgs/favicon.ico'
import moment from 'moment';
import { Grid } from '@mui/material';


//home component with a link button to form page
const ListTareas = () => {

    const [tareas, setTareas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [getPendientes, setGetPendientes] = useState(true);
    const [getTodos, setGetTodos] = useState(false);
    const [error, setError] = useState(false);

    //on form submit, call the login action
    const getTareas = async (filter) => {
        setLoading(true);
        try {
            const respone = await listadoTareasRequest(filter);

            //if the response its ok navigate to the home page
            //else return the errors
            if (respone.status === 200) {
                let soretedTareas = respone.data.tareas.sort((a, b) => {
                    //sort by ESTCUM_TAREAPED. EN PROCESO first NO CUMPLIDO second CUMPLIDO third
                    if (a.ESTCUM_TAREAPED === 'EN PROCESO' && b.ESTCUM_TAREAPED === 'NO CUMPLIDO') {
                        return -1;
                    } else if (a.ESTCUM_TAREAPED === 'NO CUMPLIDO' && b.ESTCUM_TAREAPED === 'EN PROCESO') {
                        return 1;
                    } else if (a.ESTCUM_TAREAPED === 'NO CUMPLIDO' && b.ESTCUM_TAREAPED === 'CUMPLIDO') {
                        return -1;
                    } else if (a.ESTCUM_TAREAPED === 'CUMPLIDO' && b.ESTCUM_TAREAPED === 'NO CUMPLIDO') {
                        return 1;
                    } else if (a.ESTCUM_TAREAPED === 'CUMPLIDO' && b.ESTCUM_TAREAPED === 'EN PROCESO') {
                        return -1;
                    } else if (a.ESTCUM_TAREAPED === 'EN PROCESO' && b.ESTCUM_TAREAPED === 'CUMPLIDO') {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                await setTareas(soretedTareas);
            } else {
                setError(true);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
        }
    };

    useEffect(() => {
        if (getPendientes) {
            getTareas({ estado: "EN PROCESO" });
        }
        if (getTodos) {
            getTareas({});
        }
    }, [getPendientes, getTodos]);

    const getAddress = (tarea) => {
        if (tarea.CALLE_TAREAPED) {
            const piso = tarea.PISO_TAREAPED ? ", " + tarea.PISO_TAREAPED : "";
            const codigoPostal = tarea.CP_TAREAPED ? `(${tarea.CP_TAREAPED})` : "()";
            return `${tarea.CALLE_TAREAPED} ${tarea.NRO_TAREAPED}${piso} - ${tarea.LOCA_TAREAPED} ${codigoPostal}`;
        } else {
            return "---";
        }
    }

    const getStatusChip = (tarea) => {
        if (tarea.ESTCUM_TAREAPED === "EN PROCESO") {
            return <div className='yellow-chip mr-2'></div>;
        } else if (tarea.ESTCUM_TAREAPED === "CUMPLIDO") {
            return <div className='green-chip mr-2'></div>;
        } else if (tarea.ESTCUM_TAREAPED === "NO CUMPLIDO") {
            return <div className='red-chip mr-2'></div>;
        } else if (tarea.ESTCUM_TAREAPED === "CANCELADO") {
            return <div className='grey-chip mr-2'></div>;
        } else {
            return <div className='grey-chip mr-2'></div>;
        }
    }

    const getBackgroundColor = (estado) => {
        if (estado === "EN PROCESO") {
            return "#dfaa52";
        } else if (estado === "CUMPLIDO") {
            return "#36838d";
        } else if (estado === "NO CUMPLIDO") {
            return "#5d6b74";
        } else if (estado === "CANCELADO") {
            return "#970e16";
        } else {
            return "#970e16";
        }
    }

    const getDiasDeAtencion = (tarea) => {
        let dias = [];
        if (tarea.SILUN_TAREAPED && tarea.SILUN_TAREAPED === "SI") dias.push("Lunes");
        if (tarea.SIMAR_TAREAPED && tarea.SIMAR_TAREAPED === "SI") dias.push("Martes");
        if (tarea.SIMIE_TAREAPED && tarea.SIMIE_TAREAPED === "SI") dias.push("Miércoles");
        if (tarea.SIJUE_TAREAPED && tarea.SIJUE_TAREAPED === "SI") dias.push("Jueves");
        if (tarea.SIVIE_TAREAPED && tarea.SIVIE_TAREAPED === "SI") dias.push("Viernes");

        return dias.join(", ");
    }


    const mapEstados = (estado) => {
        if (estado === "EN PROCESO") {
            return "PENDIENTE";
        } else {
            return estado;
        }
    }

    return (
        <div style={{ maxWidth: "96vw" }}>
            <TopBar />
            <StandardResponsiveContainer>
                <div class="row justify-content-center bg-sanofi" style={{ position: "sticky" }}>
                    <span class="container-fluid tituloP">Direcciones</span>
                </div>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ borderBottom: "solid black 0.25px", marginBottom: "10px", position: "sticky" }}>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <span className='filterLabel' style={{ width: "auto" }}>
                            <Checkbox
                                checked={getPendientes}
                                size="small"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setGetTodos(false);
                                        setGetPendientes(true);
                                    } else {
                                        setGetTodos(true);
                                        setGetPendientes(false);
                                    }
                                }}
                            /> En proceso
                        </span>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <span className='filterLabel' style={{ width: "auto" }}>
                            <Checkbox
                                checked={getTodos}
                                size="small"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setGetTodos(true);
                                        setGetPendientes(false);
                                    } else {
                                        setGetTodos(false);
                                        setGetPendientes(true);
                                    }
                                }}
                            />Todas
                        </span>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className='filterLabel'>
                        {moment.utc().format('DD/MM/YYYY')}
                    </Grid>

                </Grid>
                { error ? <div className='error-container'> Se produjo un error al cargar las direcciones. </div> : null }
                {
                    loading ? (
                        <div className='loading-container'> </div>
                    ) : (
                        <div>
                            {Array.isArray(tareas) ? tareas.map((tarea, index) => {
                                return (
                                    <Accordion
                                        style={{
                                            borderRadius: '10px',
                                            marginBottom: "10px",
                                        }}
                                        key={"tarea" + index}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                borderRadius: '10px',
                                                backgroundColor: getBackgroundColor(tarea.ESTCUM_TAREAPED),
                                            }}
                                        >
                                            <Grid direction="column" style={{ width: "100%" }}>
                                                <h5 className='text-left idEntrega'>
                                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                            {mapEstados(tarea.ESTCUM_TAREAPED)}
                                                        </Grid>
                                                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                        </Grid>
                                                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                            <span style={{ textAlign: "end" }}> N°{tarea.ID_PEDIDO}</span>
                                                        </Grid>
                                                    </Grid>
                                                </h5>

                                                <h5 class="mb-0 text-left idEntrega">
                                                    <span>{tarea.EMPRE_TAREAPED}</span>
                                                </h5>
                                                <h5 class="mb-0 text-left idEntrega">
                                                    <span>{getAddress(tarea)}</span>
                                                </h5>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div id={"collapse" + index} class="collapse show">
                                                <div class="card-body p-0">
                                                    <div class="row justify-content-center mt-3" className='tareasDetails'>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Empresa:</span></div>
                                                            <div class="col-6"><span class="border border-white fs-italic">{tarea.EMPRE_TAREAPED}</span></div>
                                                        </div>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Nombre:</span></div>
                                                            <div class="col-6"><span class="border border-white fs-italic">{tarea.NOMBRE_TAREAPED} {tarea.APELLIDO_TAREAPED}</span></div>
                                                        </div>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Telefono:</span></div>
                                                            <div class="col-6"><span class="border border-white fs-italic">{tarea.TELEFONO_TAREAPED}</span></div>
                                                        </div>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Dias de atencion:</span></div>
                                                            <div class="col-6"><span class="border border-white fs-italic">{getDiasDeAtencion(tarea)}</span></div>
                                                        </div>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Horario de atencion:</span></div>
                                                            <div class="col-6"><span class="border border-white fs-italic">{tarea.HORARIO_TAREAPED}</span></div>
                                                        </div>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Detalle:</span></div>
                                                            <div class="col-6"><span class="border border-white fs-italic">{tarea.DES_PEDIDO}</span></div>
                                                        </div>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Ubicacion:</span></div>
                                                            <div class="col-6">
                                                                {tarea.MAP_TAREAPED && tarea.MAP_TAREAPED !== "" ?
                                                                    (<a target="_blank" href={tarea.MAP_TAREAPED} class="border border-white fs-italic">Ver mapa</a>) :
                                                                    (<></>)
                                                                }
                                                            </div>
                                                        </div>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Solitante:</span></div>
                                                            <div class="col-6"><span class="border border-white fs-italic">{`${tarea.NOM_EMPLEADO} ${tarea.APE_EMPLEADO}`}</span></div>
                                                        </div>
                                                        <div class="col-12 d-flex">
                                                            <div class="col-6"><span class="eti">Cel. Sol:</span></div>
                                                            <div class="col-6"><span class="border border-white fs-italic">{tarea.CELU_EMPLEADO ? <a href={`tel:+${tarea.CELU_EMPLEADO}`}>{tarea.CELU_EMPLEADO}</a> : "-"}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {tarea.ESTCUM_TAREAPED === "EN PROCESO" ? (
                                                    <div class="card-footer p-0">
                                                        <div class="col-12 p-0 d-flex">
                                                            <div class="col-6 p-0">
                                                                <Link to={"/realizar/" + tarea.ID_TAREAPED} type="button" class="btn btn-success btn-lg btn-block"> <i class="bi bi-check-lg"></i> Realizado</Link>
                                                            </div>
                                                            <div class="col-6 p-0">
                                                                <Link to={"/rechazar/" + tarea.ID_TAREAPED} type="button" class="btn btn-danger btn-lg btn-block"> <i class="bi bi-x-lg"></i> No realizado</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                            </div>
                                        </AccordionDetails>

                                    </Accordion>
                                )
                            }) : (<div></div>)
                            }


                        </div>
                    )

                }
            </StandardResponsiveContainer >
        </div >
    );
}

export default ListTareas;