import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import tick from '../../assets/imgs/tick.png';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Link } from "react-router-dom";
import moment from 'moment';
import getTarea from '../../api/getTareaRequest';
import realizarTarea from '../../api/RealizarTareaRequest';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import "./RealizarPedido.css";
import { Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const RealizarPedido = () => {

    let navigate = useNavigate();

    const { id } = useParams();
    const [tarea, setTarea] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [comentario, setComentario] = useState('');
    const [nombre, setNombre] = useState('');
    const [dni, setDni] = useState('');
    const [apellido, setApellido] = useState('');
    const [base64Foto, setBase64Foto] = useState(null);
    const [fotos, setFotos] = useState([]);
    const [verFotos, setVerFotos] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [responseLoading, setResponseLoading] = useState(false);

    const fetchTarea = async (id) => {
        setLoading(true);
        const respone = await getTarea(id);

        if (respone.status === 200) {
            await setTarea(respone.data.tarea);
        } else {
            setError(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchTarea(id);
    }, []);

    const handleComentarioChange = (event) => {
        setComentario(event.target.value);
    };

    const removeFotoFromArray = (index) => {
        const newFotos = fotos.filter((foto, i) => i !== index);
        setFotos(newFotos);
    };

    const encodeImageFileAsURL = (element) => {
        setErrors([]);
        var file = element.files[0];
        if (!file.type.includes('image/')) {
            setShowErrorToast(true);
            return;
        }
        var reader = new FileReader();
        reader.onloadend = function () {
            setBase64Foto(reader.result);
            const newFotos = [...fotos, reader.result];
            setFotos(newFotos);
        }
        reader.readAsDataURL(file);
    }

    const confirmarTarea = async () => {

        try {
            setResponseLoading(true);
            const tarea = {
                id: id,
                comentario: comentario,
                nombre: nombre,
                dni: dni,
                apellido: apellido,
                fotos: fotos == [] ? null : fotos
            };

            let errs = [];
            if (!tarea.nombre) errs.push('El nombre es requerido');
            if (!tarea.dni) errs.push('El DNI es requerido');
            if (dni && dni.length < 7) errs.push('El DNI debe tener al menos 7 dígitos');
            if (!tarea.apellido) errs.push('El apellido es requerido');

            if (errs.length > 0) {
                setErrors(errs);
                return;
            }

            const response = await realizarTarea(tarea);

            if (response.status === 200) {
                navigate('/listaTareas');
            } else {
                setError(true);
                setErrors(response.data.error);
            }
            setResponseLoading(false);
        } catch (error) {
            setError(true);
            setErrors("Ocurrio un error al realizar la tarea");
            setResponseLoading(false);
        }

    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowErrorToast(false)}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div style={{ maxWidth: "96vw" }}>
            <Snackbar
                open={showErrorToast}
                autoHideDuration={6000}
                onClose={() => setShowErrorToast(false)}
                message="El Archivo tiene una extensión no permitida"
                action={action}
            />
            <div class="row justify-content-center">
                <img src={tick} class="imgNoti" alt="imagen confirmacion pedido" />
            </div>
            <div class="row justify-content-center">
                <span class="tramiteR">Confirmar Entrega.</span>
            </div>
            <div class="row justify-content-center mt-3 bg-verde">
                <span class="container-fluid nroEntrega">Direccion</span>
                <span class="container-fluid nroEntrega">Tramite N° {tarea.ID_PEDIDO}</span>
            </div>
            <div class="row justify-content-center mt-3">
                <div class="col-12 d-flex">
                    <div class="col-6"><span class="eti">Fecha de tramite:</span></div>
                    <div class="col-6"><span class="border border-white fs-italic">{
                        tarea.FEC_TAREAPED
                            ? moment(tarea.FEC_TAREAPED).format("DD/MM/YYYY")
                            : "---"
                    }</span></div>
                </div>
                <div class="col-12 d-flex">
                    <div class="col-6"><span class="eti">Empresa:</span></div>
                    <div class="col-6"><span class="border border-white fs-italic">{tarea.EMPRE_TAREAPED}</span></div>
                </div>
                <div class="col-12 d-flex">
                    <div class="col-6"><span class="eti">Nombre:</span></div>
                    <div class="col-6"><span class="border border-white fs-italic">{tarea.NOMBRE_TAREAPED} {tarea.APELLIDO_TAREAPED}</span></div>
                </div>
                <div class="col-12 d-flex">
                    <div class="col-6"><span class="eti">Calle:</span></div>
                    <div class="col-6"><span class="border border-white fs-italic">{tarea.CALLE_TAREAPED}</span></div>
                </div>
                <div class="col-12 d-flex">
                    <div class="col-6"><span class="eti">Numero:</span> <span class="border border-white fs-italic">{tarea.NRO_TAREAPED}</span></div>
                    <div class="col-6"><span class="eti">Piso:</span> <span class="border border-white fs-italic">{tarea.PISO_TAREAPED}</span></div>
                </div>
                <div class="col-12 d-flex">
                    <div class="col-6"><span class="eti">Localidad:</span></div>
                    <div class="col-6"><span class="border border-white fs-italic">{tarea.LOCA_TAREAPED}</span></div>
                </div>
            </div>
            <div class="row justify-content-center mt-3">
                <h5 class="text-bold">Comentarios</h5>
                <div class="input-group mx-5">
                    <TextareaAutosize
                        aria-label="comentario"
                        minRows={3}
                        placeholder="Ingrese un comentario"
                        style={{ minWidth: 200 }}
                        className='form-control fs-italic'
                        onChange={handleComentarioChange}
                    />
                </div>
                <h5 class="text-bold">Nombre</h5>
                <div class="input-group mx-5">
                    <TextField className="realizarFormInput" label="Nombre" value={nombre} onChange={(e) => {
                        setErrors([]);
                        setNombre(e.target.value)
                    }} />
                </div>
                <h5 class="text-bold">Apellido</h5>
                <div class="input-group mx-5">
                    <TextField className="realizarFormInput" label="Apellido" value={apellido} onChange={(e) => {
                        setErrors([]);
                        setApellido(e.target.value)
                    }} />
                </div>
                <h5 class="text-bold">DNI</h5>
                <div class="input-group mx-5">
                    <TextField className="realizarFormInput" label="DNI" value={dni} onChange={(e) => {
                        setErrors([]);
                        //only allow numbers
                        setDni(e.target.value.replace(/[^0-9]/g, ''))
                    }} />
                </div>
            </div>
            <br></br>
            <div class="row justify-content-center">
                <label for="cam-file-upload" class="custom-file-upload">
                    Tomar fotografía
                </label>
                <input
                    id="cam-file-upload"
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={(event) => {
                        encodeImageFileAsURL(event.target);
                    }}
                />
                <label for="file-upload" class="custom-file-upload">
                    Adjuntar fotografía
                </label>
                <input
                    id="file-upload"
                    type="file"
                    onChange={(event) => {
                        encodeImageFileAsURL(event.target);
                    }}
                />
            </div>
            {verFotos ? (
                <div class="row justify-content-center align-content-center">
                    <div class="column justify-content-center align-content-center">
                        <Button onClick={() => setVerFotos(false)}>Ocultar Fotos</Button><br /><br />
                        <div class="column justify-content-center align-content-center">
                            {fotos.map((foto, index) => {
                                return (
                                    <div key={"foto" + index} class="row justify-content-center" style={{ marginTop: "10px" }}>
                                        <img className='img-preview' style={{ maxWidth: "45%", maxHeight: "15vh", marginRight: "10px" }} src={foto} alt="" />
                                        <CancelIcon onClick={() => removeFotoFromArray(index)}>Borrar foto</CancelIcon>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            ) : (
                <div class="row justify-content-center align-content-center"><br />
                    <Button onClick={() => setVerFotos(true)}>Ver Fotos</Button>
                    {fotos && fotos.length > 0 ? (<div className='blue-circle'>{fotos.length}</div>) : (<></>)}
                </div>
            )
            }
            <br />
            {
                errors.length > 0 && errors.map((error, index) => {
                    return (
                        <p key={"error" + index} style={{ width: "80%", marginLeft: "10%", color: "red" }} role="alert">
                            {error}
                        </p>
                    )
                })
            }
            <div class="row justify-content-center mt-3">
                <button type="button" class="btn btn-guardarComentario" onClick={confirmarTarea}>Confirmar</button>
                {responseLoading ? (<CircularProgress />) : (<></>)}
            </div>
            <div class="row justify-content-center my-5">
                <Link to="/listaTareas" class="volver"><i class="far fa-arrow-alt-circle-left "></i> Volver a direcciones</Link>
            </div>

        </div>
    )
}

export default RealizarPedido;