//react login component
//use react router dom to navigate to other pages
//dont use redux for login

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
//use navigate from react router dom
import { useNavigate } from 'react-router-dom';
import sanofiLogo from "../assets/iconos/sanofi/Logo_Login.png";
import loginMensajeroRequest from '../api/loginMensajeroRequest.js';
import "./loginPage.css"

class Login extends Component {
  state = {
    email: '',
    password: '',
    errors: {}
  };

  //constructor to set state
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      error: false,
      errorMsg: ''
    };
  }

  //when the component mounts, check for any errors
  componentDidMount() {
    //get IS_LOGGED in local storage
    const isLogged = localStorage.getItem('IS_LOGGED');
    //if the user is logged in, redirect to home page
    if (isLogged === "true") {
      const navTimeOut = setTimeout(() => {
        this.props.navigate("/listaTareas");
      });
    }
  }

  //when the component updates, check for any errors
  componentDidUpdate(prevProps) {

  }

  //on input change, update the state
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    //if email or password are changed clear the errors
    let newErr = this.state.errors;
    if (e.target.name === 'email') {
      newErr.email = '';
      this.setState({ errors: newErr });
    }
    if (e.target.name === 'password') {
      newErr.password = '';
      this.setState({ errors: newErr });
    }
  };

  //on form submit, call the login action
  onSubmit = async e => {
    e.preventDefault();
    const { email, password } = this.state;

    try {

      //check for errors, push errors to state as an array
      const errors = {};
      if (email === '') errors.email = 'Email is required';
      if (password === '') errors.password = 'Password is required';

      //if there are errors, return the errors
      if (Object.keys(errors).length > 0) {
        this.setState({ errors });
        return;
      }

      const respone = await loginMensajeroRequest(email, password);

      //if the response its ok navigate to the home page
      //else return the errors
      if (respone.status === 200) {
        //get token from response and save it to local storage
        const token = respone.data.token;
        localStorage.setItem('token', token);
        //save NOM_USUARI and APE_USUARI to local storage
        const user = respone.data.user;
        localStorage.setItem('NOM_USUARI', user.NOM_USUARI);
        localStorage.setItem('APE_USUARI', user.APE_USUARI);
        localStorage.setItem('IS_LOGGED', "true");
        this.props.navigate("/listaTareas")
      } else {
        this.setState({ error: true, errorMsg: respone.data.errorMsg });
      }
    } catch (error) {
      this.setState({ error: true, errorMsg: "Ocurrió un error al tratar de ingresar." });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      //responsive login form, 1 column for mobile, desktop and tablet
      <div className="container login-container">

        <div className="row justify-content-center align-items-center">
          <div className="col">
            <div className="row justify-content-center align-items-center">
              <img src={sanofiLogo} alt="Sanofi" className="login-logo" />
            </div>
            <div className="col-md-6 mt-5 mx-auto text-center">
              <Form noValidate onSubmit={this.onSubmit}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                  {errors.email && <div className="alert alert-danger">{errors.email}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                  {errors.password && <div className="alert alert-danger">{errors.password}</div>}
                </FormGroup>
                <Button className="btn-block login-button">
                  Login
                </Button>
              </Form>
              {this.state.error && <div className="alert alert-danger">{this.state.errorMsg}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function LoginWithNavigate(props) {
  let navigate = useNavigate();
  return <Login {...props} navigate={navigate} />
}

export default LoginWithNavigate
